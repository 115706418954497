import React, { FC } from 'react';
import cn from 'classnames';

type TRow = {
  title: string;
  description: string;
};

type TConfig = {
  heading: string;
  rows: TRow[];
};

interface TInfoWidgetProps {
  className?: string;
  config?: TConfig;
  label?: string;
  styleType?: 'typeA' | 'typeB' | 'typeC';
}

const InfoWidget: FC<TInfoWidgetProps> = ({ className, config = {}, label, styleType }) => {
  const { heading, rows } = config;

  const infoWidgetClassName = cn('info-widget', className, styleType, {
    sng: label,
  });

  return (
    <div className={infoWidgetClassName}>
      {label ? (
        <p className='single-label'>{label}</p>
      ) : (
        <>
          <p className='info-heading'>{heading}</p>
          <div className='widget-rows'>
            {(rows || []).map((item: TRow, index: number) => (
              <div key={index} className='wRow'>
                <div className='title'>{item.title}</div>
                <div className='description'>{item.description}</div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default InfoWidget;
