import React from 'react';

import AnimatedShape from './AnimatedShape/AnimatedShape';
import useDeviceTypeDetector from '../../../../hooks/useDeviceTypeDetector/useDeviceTypeDetector';

import './styles.scss';

const AnimationLayer = () => {
  const { isMobile } = useDeviceTypeDetector();

  return (
    <div className='animation-layer'>
      <AnimatedShape />
      {!isMobile && <AnimatedShape delay={2000} />}
    </div>
  );
};

export default AnimationLayer;
