import React from 'react';
import Text from '../../../ui/Text/Text';
import './idealMatch.scss';

const options = [
  { label: 'Начать больше зарабатывать, но не знает как' },
  { label: 'Открыть свое дело, но не понимает, с чего начать' },
  { label: 'Найти бизнес-идею и проанализировать риски' },
  { label: 'Составить план запуска бизнеса и сделать первый шаг' },
];

function IdealMatch() {
  return (
    <section className='ideal-match'>
      <Text variant='h3' fontWeight={600} className='label'>
        ИДЕАЛЬНО ДЛЯ ТЕХ, КТО ХОЧЕТ
      </Text>
      <div className='container'>
        <div className='ideal-match-grid'>
          {options.map(({ label }, index) => (
            <div className='match-item' key={index}>
              <Text variant='subtitle3' fontWeight={400} className='custom-sub-title'>
                {label}
              </Text>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default IdealMatch;
