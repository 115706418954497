import React from 'react';
import cn from 'classnames';

import Icon from '../Icon/Icon';
import Text from '../Text/Text';

import { IInputProps } from './Input.types';

import './input.scss';

const Input = React.forwardRef<HTMLInputElement, IInputProps>(
  ({ iconName, className, size, message, variant = 'primary', hasError, ...props }, ref) => {
    const inputWrapperClassName = cn(
      'input-wrapper',
      className,
      variant,
      size,
      hasError && 'error'
    );

    return (
      <div className={inputWrapperClassName}>
        <label htmlFor={props.id} className='input-holder'>
          {iconName && <Icon name={iconName} size={20} className='icon' />}
          <input {...props} ref={ref} />
        </label>
        {message && (
          <Text variant='body3' className='message'>
            {message}
          </Text>
        )}
      </div>
    );
  }
);

Input.displayName = 'Input';

export default Input;
