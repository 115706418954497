import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

import Text from '../../ui/Text/Text';

import ConditionItem from './ConditionItem';

import useDeviceTypeDetector from '../../hooks/useDeviceTypeDetector/useDeviceTypeDetector';

import iconFree from '../../assets/media/weProvideConditions/icon-free.svg';
import iconChunks from '../../assets/media/weProvideConditions/icon-chunks.svg';
import iconSafety from '../../assets/media/weProvideConditions/icon-safety.svg';
import iconPercent from '../../assets/media/weProvideConditions/icon-percent.svg';
import iconBenefit from '../../assets/media/weProvideConditions/icon-benefit.svg';
import iconOpacity from '../../assets/media/weProvideConditions/icon-opacity.svg';
import iconFreeSmall from '../../assets/media/weProvideConditions/icon-free-small.svg';
import iconChunksSmall from '../../assets/media/weProvideConditions/icon-chunks-small.svg';
import iconSafetySmall from '../../assets/media/weProvideConditions/icon-safety-small.svg';
import iconPercentSmall from '../../assets/media/weProvideConditions/icon-percent-small.svg';
import iconBenefitSmall from '../../assets/media/weProvideConditions/icon-benefit-small.svg';
import iconOpacitySmall from '../../assets/media/weProvideConditions/icon-opacity-small.svg';

import './weProvideConditions.scss';

import 'swiper/css';
import 'swiper/css/pagination';

interface IConditionConfig {
  title1: string;
  title2: string;
  description: string;
  images: string[];
}

const itemsConfig: IConditionConfig[] = [
  {
    title1: 'Начни ',
    title2: 'бесплатно',
    description: 'Получи персональный разбор на бесплатной консультации.',
    images: [iconFree, iconFreeSmall],
  },
  {
    title1: 'Плати ',
    title2: 'частями',
    description: 'Не нужно платить за весь курс: плати частями на пакетах от 2 месяцев.',
    images: [iconChunks, iconChunksSmall],
  },
  {
    title1: 'Занимайся со ',
    title2: 'скидкой',
    description: 'Получи скидку на покупку больших пакетов.',
    images: [iconPercent, iconPercentSmall],
  },
  {
    title1: 'Получи ',
    title2: 'выгоду',
    description: 'Пригласи друга и мы сделаем вам обоим по 10% скидки.',
    images: [iconBenefit, iconBenefitSmall],
  },
  {
    title1: 'Безопасность ',
    title2: 'платежей',
    description: 'Защищаем платежи технологией HTTPS и двухфакторной аутентификацией 3D Secure.',
    images: [iconSafety, iconSafetySmall],
  },
  {
    title1: 'Прозрачный ',
    title2: 'баланс',
    description: 'Отображаем баланс твоих платежей.',
    images: [iconOpacity, iconOpacitySmall],
  },
];

const WeProvideConditions = () => {
  const { isTablet, isMobile } = useDeviceTypeDetector();

  return (
    <section className='we-provide-conditions'>
      <div className='container'>
        <div className='conditions-holder'>
          <Text variant='h3' fontWeight={600} className='title' textTransform='upperCase'>
            Мы обеспечиваем лучшие условия
          </Text>
          {isTablet || isMobile ? (
            <div className='conditions-slider'>
              <Swiper
                loop
                pagination
                centeredSlides
                slidesPerView='auto'
                spaceBetween={8}
                modules={[Pagination]}
              >
                {itemsConfig.map(({ images, ...props }: IConditionConfig, index: number) => (
                  <SwiperSlide key={index}>
                    <ConditionItem {...props} image={images[1]} key={index} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          ) : (
            <div className='conditions-grid'>
              {itemsConfig.map(({ images, ...props }: IConditionConfig, index: number) => (
                <ConditionItem {...props} image={images[0]} key={index} />
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default WeProvideConditions;
