import React from 'react';

import icon from '../../assets/media/icons/arrow-right.svg';

import './arrowButton.scss';

const ArrowButton = () => (
  <button type='button' className='arrow-button'>
    <img src={icon} alt='arrow right' />
  </button>
);

export default ArrowButton;
