import React from 'react';

import Text from '../../../ui/Text/Text';
import Icon from '../../../ui/Icon/Icon';
import Button from '../../../ui/Button/Button';

import useDeviceTypeDetector from '../../../hooks/useDeviceTypeDetector/useDeviceTypeDetector';

import { scrollElementIntoView } from '../../../utils/helpers';
import { freeConsultationId } from '../FreeConsultationPage';

import castingImgLarge from '../../../assets/media/freeCasting/free-casting-large.png';
import castingImgMedium from '../../../assets/media/freeCasting/free-casting-medium.png';
import castingImgSmall from '../../../assets/media/freeCasting/free-casting-small.png';

import './individualCasting.scss';

const castingSteps = [
  { id: 0, text: 'разберешь свою ситуацию, определишь сильные и слабые стороны' },
  { id: 1, text: 'увидишь возможности для увеличения дохода' },
  { id: 2, text: 'получишь четкий план своего развития' },
  { id: 3, text: 'получишь конкретные ответы на свои вопросы' },
];

const IndividualCasting = () => {
  const { isDesktop, isMobile, isTablet } = useDeviceTypeDetector();

  return (
    <section className='individual-casting'>
      <div className='container'>
        <div className='individual-casting-holder'>
          <Text variant='h2' className='section-title' fontWeight={600} textTransform='upperCase'>
            Бесплатная консультация
          </Text>
          <div className='content'>
            <div className='info'>
              <Text variant='h3' className='info-title'>
                ИНДИВИДУАЛЬНЫЙ РАЗБОР С ЭКСПЕРТОМ
              </Text>
              <Text variant='subtitle3' className='info-label'>
                За 60 минут с экспертом ты:
              </Text>
              <ul className='casting-steps-list'>
                {castingSteps.map((skill) => (
                  <li key={skill.id} className='step-item'>
                    <Icon size={isMobile ? 16 : isTablet ? 24 : 32} name='arrowHeadRight' />
                    <Text variant='subtitle3'>{skill.text}</Text>
                  </li>
                ))}
              </ul>
              <div className='subscription-row'>
                <Text variant='secondarySubtitle2' className='subscription-lbl'>
                  Все это действительно бесплатно!
                </Text>
                <Button
                  variant='secondary'
                  size={isDesktop ? 'large' : 'small'}
                  onClick={() => scrollElementIntoView(freeConsultationId)}
                >
                  <Text variant='body1' fontWeight={600}>
                    Записаться
                  </Text>
                </Button>
              </div>
            </div>
            <div className='image'>
              <img
                src={isTablet ? castingImgMedium : isMobile ? castingImgSmall : castingImgLarge}
                alt='Free casting banner'
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IndividualCasting;
