import { deviceBreakpoints } from './constants';
import { DeviceTypeEnum } from './enums';

export function getDeviceType(deviceWidth: number) {
  if (deviceWidth >= deviceBreakpoints.desktop) {
    return DeviceTypeEnum.DesktopLarge;
  }
  if (deviceWidth >= deviceBreakpoints.laptop && deviceWidth < deviceBreakpoints.desktop) {
    return DeviceTypeEnum.DesktopSmall;
  }
  if (deviceWidth >= deviceBreakpoints.tablet && deviceWidth < deviceBreakpoints.laptop) {
    return DeviceTypeEnum.Laptop;
  }
  if (deviceWidth >= deviceBreakpoints.mobile && deviceWidth < deviceBreakpoints.tablet) {
    return DeviceTypeEnum.Tablet;
  }
  return DeviceTypeEnum.Mobile;
}

export const scrollElementIntoView = (elementId: string) => {
  const element = document.getElementById(elementId);

  if (element) {
    element.scrollIntoView(true);
  }
};
