import {IStepProps} from "./Step/Step.types";

export const SnakeStepsData: IStepProps[] = [
    {
        iconName: 'play',
        text: 'Получи бесплатную консультацию'
    },
    {
        iconName: 'chart',
        text: 'Определи свою нишу'
    },
    {
        iconName: 'document',
        text: 'Составь план достижения целей'
    },
    {
        iconName: 'work',
        text: 'Открой свой бизнес'
    }
]