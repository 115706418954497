import React from 'react';

import Text from '../../ui/Text/Text';
import FeaturesWidgetMobile from './FeaturesWidget/FeaturesWidgetMobile';
import FeaturesWidgetDesktop from './FeaturesWidget/FeaturesWidgetDesktop';

import useDeviceTypeDetector from '../../hooks/useDeviceTypeDetector/useDeviceTypeDetector';

import './comfortability.scss';

const Comfortability = () => {
  const { isDesktop, isLaptop } = useDeviceTypeDetector();

  return (
    <section className='comfortability-section'>
      <div className='container'>
        <div className='comfortability-holder'>
          <Text variant='h3' fontWeight={600} className='title' textTransform='upperCase'>
            Учиться онлайн – удобно
          </Text>
          {isDesktop || isLaptop ? <FeaturesWidgetDesktop /> : <FeaturesWidgetMobile />}
        </div>
      </div>
    </section>
  );
};

export default Comfortability;
