import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Text from '../../ui/Text/Text';
import Benefits from './Benefits/Benefits';
import CourseHero from './CourseHero/CourseHero';
import UpcomingSteps from './UpcomingSteps/UpcomingSteps';
import CourseAuditorium from './CourseAuditorium/CourseAuditorium';
import EnrollCourse from '../../components/EnrollCourse/EnrollCourse';
import WeProvideConditions from '../../components/WeProvideConditions/WeProvideConditions';
import useDeviceTypeDetector from '../../hooks/useDeviceTypeDetector/useDeviceTypeDetector';
import { useScrollToTopOnMount } from '../../hooks/useScrollToTopOnMount';

import { AppRoutes } from '../../utils/constants';
import { CourseData } from './CourseDetails.constants';

import './courseDetails.scss';

export const enrollCourseId = 'enroll-course';

const CourseDetails = () => {
  useScrollToTopOnMount();

  const { isMobile } = useDeviceTypeDetector();
  const { course } = useParams();
  const navigate = useNavigate();

  return (
    <main className='page course-details'>
      <section className='course-tabs'>
        <div className='container'>
          <div className='course-tabs-holder'>
            {Object.entries(CourseData).map(([coursePath, { name, shortName }]) => (
              <button
                key={`${coursePath}`}
                className={`course-tab ${course === coursePath ? 'course-tab-active' : ''}`}
                onClick={() => navigate(`${AppRoutes.courses}/${coursePath}`)}
              >
                <Text variant={isMobile ? 'body2' : 'body1'} fontWeight={600}>
                  {isMobile ? shortName : name}
                </Text>
              </button>
            ))}
          </div>
        </div>
      </section>
      <CourseHero />
      <Benefits />
      <CourseAuditorium />
      <UpcomingSteps />
      <WeProvideConditions />
      <EnrollCourse id={enrollCourseId} title='НЕ ТЕРЯЙ ВРЕМЯ' />
    </main>
  );
};

export default CourseDetails;
