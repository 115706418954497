import React, { useState, useEffect, useRef } from 'react';

import Text from '../../../ui/Text/Text';
import PhaseItem from './PhaseItem/PhaseItem';

import { phasesRowData1, phasesRowData2 } from './BusinessPhases.mocks';

import './businessPhases.scss';

const BusinessPhases = () => {
  const [activePhaseNumber, setActivePhaseNumber] = useState(1);
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

  const maxPhaseNumber = phasesRowData1.length + phasesRowData2.length;
  const changeInterval = 2000;

  const startInterval = () => {
    intervalRef.current = setInterval(() => {
      setActivePhaseNumber((prev) => (prev < maxPhaseNumber ? prev + 1 : 1));
    }, changeInterval);
  };

  const stopInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  useEffect(() => {
    startInterval();

    return () => stopInterval();
  }, []);

  const handlePhaseClick = (phaseNumber: number) => {
    stopInterval();
    setActivePhaseNumber(phaseNumber);
    startInterval();
  };

  return (
    <section className='business-phases-section'>
      <Text variant='h3' fontWeight={600} className='section-title'>
        КАКИЕ ЭТАПЫ ТЫ ПРОЙДЕШЬ?
      </Text>
      <div className='business-phases-holder'>
        <div className='business-phases-row'>
          {phasesRowData1.map(({ phaseNumber, description }) => (
            <PhaseItem
              key={phaseNumber}
              phaseNumber={phaseNumber}
              description={description}
              isActive={phaseNumber === activePhaseNumber}
              onClick={() => handlePhaseClick(phaseNumber)}
            />
          ))}
        </div>
        <div className='phase-indicators-holder'>
          {Array.from({ length: maxPhaseNumber }, (_, index) => (
            <span
              key={`${index}`}
              className={`phase-indicator ${activePhaseNumber === index + 1 ? 'phase-indicator-active' : ''}`}
            />
          ))}
        </div>
        <div className='business-phases-row'>
          {phasesRowData2.map(({ phaseNumber, description }) => (
            <PhaseItem
              key={phaseNumber}
              phaseNumber={phaseNumber}
              description={description}
              isActive={phaseNumber === activePhaseNumber}
              onClick={() => handlePhaseClick(phaseNumber)}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default BusinessPhases;
