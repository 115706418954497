import React from 'react';

import { useScrollToTopOnMount } from '../../hooks/useScrollToTopOnMount';

import IndividualCasting from './IndividualCasting/IndividualCasting';
import IdealMatch from './IdealMatch/IdealMatch';
import Parsing from './Parsing/Parsing';
import InResult from './InResult/InResult';
import UniversalExpert from './UniversalExpert/UniversalExpert';
import FreeConsultation from '../../components/FreeConsultation/FreeConsultation';

export const freeConsultationId = 'free-consultation-section';

function FreeConsultationPage() {
  useScrollToTopOnMount();

  return (
    <main className='page'>
      <IndividualCasting />
      <IdealMatch />
      <Parsing />
      <InResult />
      <UniversalExpert />
      <FreeConsultation
        id={freeConsultationId}
        title='Не останавливайся'
        subTitle='ЗАПИШИСЬ НА РАЗБОР'
        description='Найди точки своего роста и составь пошаговый план увеличения дохода вместе с нашим экспертом'
      />
    </main>
  );
}

export default FreeConsultationPage;
