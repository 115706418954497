import React from 'react';
import { NavLink } from 'react-router-dom';

import Text from '../../../ui/Text/Text';
import Icon from '../../../ui/Icon/Icon';
import CourseGoals from './CourseGoals/CourseGoals';

import { AppRoutes } from '../../../utils/constants';
import { ICoursePacketProps } from './CoursePacket.types';

import './coursePacket.scss';

const CoursePacket: React.FC<ICoursePacketProps> = ({
  name,
  price,
  result,
  id,
  meetsCount,
  description,
  resultHighlight,
  goals,
  discountPrice,
  size = 'small',
  type = 'express',
  coursePath,
}) => (
  <div id={id} className={`course-packet course-packet-${type} course-packet-${size}`}>
    <Text
      variant='body1'
      fontWeight={600}
      className='label'
    >{`Онлайн / ${meetsCount} занятий`}</Text>
    <div className='basic-info'>
      <Text variant='subtitle3' fontWeight={500} className='name'>
        {name}
      </Text>
      {discountPrice && (
        <Text variant='h3' className='discount-price' fontWeight={500}>
          {`${discountPrice} ₽`}
        </Text>
      )}
      <Text variant='subtitle2' className='price'>
        {`${price} ₽`}
      </Text>
    </div>
    <Text variant='body1' className='description'>
      {description}
    </Text>
    <CourseGoals goals={goals} type={type} />
    <div className='result'>
      <Text variant='body1' fontWeight={500} className='result-title'>
        Твой результат:
      </Text>
      <Text variant='body1' className='result-text'>
        {result} <strong>{resultHighlight}</strong>
      </Text>
    </div>
    <NavLink to={`${AppRoutes.courses}/${coursePath}`} className='view-details'>
      <Text variant='body1' fontWeight={600}>
        Подробнее
      </Text>
      <Icon name='arrowRight' size={24} />
    </NavLink>
  </div>
);

export default CoursePacket;
