import React from 'react';

import Text from '../../../../ui/Text/Text';
import Icon from '../../../../ui/Icon/Icon';

import { IExpertProps } from './Expert.types';
import useDeviceTypeDetector from '../../../../hooks/useDeviceTypeDetector/useDeviceTypeDetector';

const Expert: React.FC<IExpertProps> = ({ hint, img, name, skills, footNote }) => {
  const { isMobile, isTablet } = useDeviceTypeDetector();

  return (
    <div className='expert-item'>
      <div className='image'>
        <img src={img.src} alt={img.alt} />
      </div>
      <div className='info'>
        {hint && (
          <Text variant='secondarySubtitle1' className='hint'>
            {hint}
          </Text>
        )}
        <Text variant='h3'>{name}</Text>
        {skills.length > 0 && (
          <ul className='skills-list'>
            {skills.map((skill) => (
              <li key={skill.id} className='skill-item'>
                <Icon size={isMobile ? 16 : isTablet ? 24 : 32} name='arrowHeadRight' />
                <Text variant='subtitle3'>{skill.text}</Text>
              </li>
            ))}
          </ul>
        )}
        {footNote && (
          <Text variant='subtitle4' className='foot-note'>
            {footNote}
          </Text>
        )}
      </div>
    </div>
  );
};

export default Expert;
