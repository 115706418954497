export const phasesRowData1 = [
    {
        phaseNumber: 1,
        description: 'Определишь свою рыночную нишу'
    },
    {
        phaseNumber: 3,
        description: 'Проверишь гипотезу и упакуешь свой продукт'
    },
    {
        phaseNumber: 5,
        description: 'Научишься привлекать первых клиентов'
    },
    {
        phaseNumber: 7,
        description: 'Закроешь свои бизнес-цели'
    }
];

export const phasesRowData2 = [
    {
        phaseNumber: 2,
        description: 'Найдешь бизнес-идею и сформулируешь свою гипотезу'
    },
    {
        phaseNumber: 4,
        description: 'Подберешь людей и соберешь свою команду'
    },
    {
        phaseNumber: 6,
        description: 'Построишь свою финансовую систему'
    }
];