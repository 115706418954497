export const AppRoutes = {
  home: '/',
  school: '/entrepreneur-school',
  courses: '/courses',
  freeConsultation: '/free-consultation',
};

export const RequiredErrorMessage = 'Обязательное поле';

export const InvalidEmailErrorMessage = 'Неверный формат';

export const InvalidPhoneErrorMessage = 'Неверный формат';

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const deviceBreakpoints = {
  desktop: 1680,
  laptop: 1280,
  tablet: 1024,
  mobile: 768,
};

export const ROOT_ID = 'root';

export const APP_ID = 'application';
