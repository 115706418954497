import React from 'react';
import cn from 'classnames';
import DOMPurify from 'isomorphic-dompurify';

import { ITextProps } from './Text.types';
import { TextVariantTagMap, TextWeightClassNameMap } from './Text.constants';

import './text.scss';

const Text: React.FC<ITextProps> = ({
  children,
  className,
  fontWeight = 400,
  textTransform,
  variant,
  isItalic = false,
  isEllipsis = false,
  isUnderLined = false,
}) => {
  const textComponentCn = cn(
    className,
    variant,
    textTransform,
    [TextWeightClassNameMap[fontWeight]],
    {
      italic: isItalic,
      ellipsis: isEllipsis,
      underLined: isUnderLined,
    }
  );
  const TextComponent = TextVariantTagMap[variant];

  const getMarkup = (text: string) => {
    const lines = text.split(/\n/);
    const parsedText = lines.join('<br>');

    const sanitizedHtmlContent = DOMPurify.sanitize(parsedText);
    return { __html: sanitizedHtmlContent };
  };

  if (typeof children === 'string') {
    return (
      <TextComponent dangerouslySetInnerHTML={getMarkup(children)} className={textComponentCn} />
    );
  }

  return <TextComponent className={textComponentCn}>{children}</TextComponent>;
};

export default Text;
