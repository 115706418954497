import React from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Text from '../../../ui/Text/Text';
import Input from '../../../ui/Input/Input';
import Button from '../../../ui/Button/Button';

import {
  InvalidEmailErrorMessage,
  InvalidPhoneErrorMessage,
  phoneRegExp,
  RequiredErrorMessage,
} from '../../../utils/constants';
import useDeviceTypeDetector from '../../../hooks/useDeviceTypeDetector/useDeviceTypeDetector';

type TFormInputs = {
  name: string;
  email: string;
  phone: string;
};

const ConsultationForm = () => {
  const { isDesktop } = useDeviceTypeDetector();

  const consultationFormSchema = Yup.object().shape({
    name: Yup.string().required(RequiredErrorMessage),
    email: Yup.string().email(InvalidEmailErrorMessage).required(RequiredErrorMessage),
    phone: Yup.string()
      .required(RequiredErrorMessage)
      .matches(phoneRegExp, InvalidPhoneErrorMessage),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<TFormInputs>({
    resolver: yupResolver(consultationFormSchema),
    shouldFocusError: true,
  });

  // eslint-disable-next-line no-console
  const onSubmit = (data: TFormInputs) => console.log(data);

  const controlSize = isDesktop ? 'large' : 'small';

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        iconName='user'
        placeholder='Имя'
        className='form-input'
        size={controlSize}
        message={errors.name?.message}
        hasError={!!errors.name?.message}
        {...register('name', { required: true })}
      />
      <Input
        iconName='envelopeSimple'
        placeholder='Email'
        className='form-input'
        size={controlSize}
        hasError={!!errors.email?.message}
        message={errors.email?.message}
        {...register('email', { required: true })}
      />
      <Input
        iconName='phoneCall'
        placeholder='Телефон'
        className='form-input'
        size={controlSize}
        message={errors.phone?.message}
        hasError={!!errors.phone?.message}
        {...register('phone', { required: true })}
      />
      <Button type='submit' variant='tertiary' size={controlSize} fullWidth>
        <Text variant='body1' fontWeight={600}>
          Оставить заявку
        </Text>
      </Button>
      <Text variant='body2' className='policy'>
        Оставляя заявку, ты принимаешь условия{' '}
        <a href={window.location.href}>Политики конфиденциальности</a>
      </Text>
    </form>
  );
};

export default ConsultationForm;
