import React, { ButtonHTMLAttributes } from 'react';

import Text from '../../../../ui/Text/Text';
import useDeviceTypeDetector from '../../../../hooks/useDeviceTypeDetector/useDeviceTypeDetector';

import './phaseItem.scss';

export interface TPhaseItem extends ButtonHTMLAttributes<HTMLButtonElement> {
  isActive: boolean;
  phaseNumber: number;
  description: string;
}

const PhaseItem: React.FC<TPhaseItem> = ({ phaseNumber, description, isActive, ...props }) => {
  const { isMobile } = useDeviceTypeDetector();

  return (
    <button className={`phase-item ${isActive ? 'phase-item-active' : ''}`} {...props}>
      <Text variant={isMobile ? 'body1' : 'h3'} fontWeight={500} className='phase-number'>
        {phaseNumber}
      </Text>
      <Text
        variant={isMobile ? 'body2' : 'subtitle2'}
        fontWeight={500}
        className='phase-description'
      >
        {description}
      </Text>
    </button>
  );
};

export default PhaseItem;
