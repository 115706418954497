import React from 'react';

import Text from '../../../ui/Text/Text';
import Expert from './Expert/Expert';

import { expertsData } from './OurExperts.mocks';

import './ourExperts.scss';

const OurExperts = () => (
  <section className='container our-experts-section'>
    <Text variant='h3' fontWeight={600} className='section-title'>
      НАШИ ЭКСПЕРТЫ
    </Text>
    <div className='experts-holder'>
      {expertsData.map((item, i) => (
        <Expert key={`${i}`} {...item} />
      ))}
    </div>
  </section>
);

export default OurExperts;
