import React from 'react';
import Text from '../../../ui/Text/Text';
import './parsing.scss';

type TConfigItem = {
  label: string;
  description: string;
};
const config: TConfigItem[] = [
  {
    label: 'ZOOM',
    description: 'Встреча в Zoom',
  },
  {
    label: '60 МИНУТ',
    description: '60 минут общения с экспертом',
  },
  {
    label: 'ЦЕЛИ',
    description: 'Разбор твоей ситуации и постановка целей',
  },
];

const Parsing = () => (
  <section className='parsing'>
    <Text variant='h3' fontWeight={600} className='title'>
      РАЗБОР – ЭТО
    </Text>
    <div className='container'>
      <div className='parsing-grid'>
        {config.map((item, index) => {
          const { label, description } = item;
          return (
            <div className='parsing-item' key={index}>
              <div className='label'>
                <Text variant='body1' fontWeight={600}>
                  {label}
                </Text>
              </div>
              <div className='description'>
                <Text variant='subtitle1' fontWeight={400}>
                  {description}
                </Text>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  </section>
);

export default Parsing;
