import { useEffect } from 'react';

import useDeviceTypeDetector from './useDeviceTypeDetector/useDeviceTypeDetector';

import { APP_ID } from '../utils/constants';

export const useScrollToTopOnMount = () => {
  const { isDesktop, isLaptop } = useDeviceTypeDetector();

  const isDesktopSize = isDesktop || isLaptop;

  useEffect(() => {
    const scrollWrapper = isDesktopSize ? window : document.getElementById(APP_ID);
    // @ts-ignore  - behavior: "instant" is eligible
    // - - - - - - - specification: https://drafts.csswg.org/cssom-view/#enumdef-scrollbehavior
    scrollWrapper.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  }, [isDesktopSize]);
};
