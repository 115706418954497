import React from 'react';
import { NavLink } from 'react-router-dom';

import Icon from '../../ui/Icon/Icon';
import Text from '../../ui/Text/Text';

import { AppRoutes } from '../../utils/constants';
import Logo from '../../assets/media/logo-white.svg';

import './footer.scss';

const Footer = () => (
  <footer className='footer'>
    <div className='container'>
      <div className='footer-holder'>
        <div className='company-info-holder flex-column'>
          <img src={Logo} alt='Logo white' className='logo' />
          <ul className='contacts-holder flex-column'>
            <li className='phone-number'>
              <Icon name='phoneWhite' size={20} />
              <Text variant='body3'>8 (800) 000-00-00</Text>
            </li>
            <li className='phone-number'>
              <Icon name='envelopeWhite' size={20} />
              <a href='mailto:skybox@gmail.com'>
                <Text variant='body3'>skybox@gmail.com</Text>
              </a>
            </li>
          </ul>
          <div className='requisites-holder flex-column'>
            <Text variant='body2'>Сведения о юридическом лице</Text>
            <ul className='flex-column'>
              <li>
                <Text variant='body2'>ООО «СКАЙБОКС»</Text>
              </li>
              <li>
                <Text variant='body2'>ИНН 3528320935</Text>
              </li>
              <li>
                <Text variant='body2'>ОГРН 1023500022648</Text>
              </li>
            </ul>
          </div>
        </div>
        <div className='site-map-holder flex-column'>
          <Text variant='body2' fontWeight={500}>
            Сайт
          </Text>
          <nav>
            <ul className='flex-column'>
              <li>
                <NavLink to={AppRoutes.home}>
                  <Text variant='body2'>Главная</Text>
                </NavLink>
              </li>
              <li>
                <NavLink to={AppRoutes.school}>
                  <Text variant='body2'>Школа предпринимателя</Text>
                </NavLink>
              </li>
              <li>
                <NavLink to={AppRoutes.courses}>
                  <Text variant='body2'>Курсы</Text>
                </NavLink>
              </li>
              <li>
                <NavLink to={AppRoutes.freeConsultation}>
                  <Text variant='body2'>Бесплатная консультация</Text>
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
        <div className='documents-map-holder flex-column'>
          <Text variant='body2' fontWeight={500}>
            Документы
          </Text>
          <nav>
            <ul className='flex-column'>
              <li>
                <NavLink to={AppRoutes.home}>
                  <Text variant='body2'>Договор-оферта</Text>
                </NavLink>
              </li>
              <li>
                <NavLink to={AppRoutes.home}>
                  <Text variant='body2'>Условия использования</Text>
                </NavLink>
              </li>
              <li>
                <NavLink to={AppRoutes.home}>
                  <Text variant='body2'>
                    Политика обработки <br />
                    персональных данных
                  </Text>
                </NavLink>
              </li>
              <li>
                <NavLink to={AppRoutes.home}>
                  <Text variant='body2'>Политика конфиденциальности</Text>
                </NavLink>
              </li>
              <li>
                <NavLink to={AppRoutes.home}>
                  <Text variant='body2'>Лицензия</Text>
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
        <div className='copyright-holder'>
          <div className='social-links'>
            <a href='https://vk.com/skyboxbusiness' aria-label='vkontakte' target='_blank' rel="noreferrer">
              <Icon name='vk' size={24} />
            </a>
            <a href='https://t.me/skyboxbusiness' aria-label='telegram' target='_blank' rel="noreferrer">
              <Icon name='telegram' size={24} />
            </a>
          </div>
          <Text variant='body2' className='copyright'>
            &copy; 2023 SkyBox
          </Text>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
