import React from 'react';

import Text from '../../../../ui/Text/Text';
import Button from '../../../../ui/Button/Button';

import founderImage from '../../../../assets/media/founder-image.png';

import { scrollElementIntoView } from '../../../../utils/helpers';

import './banner.scss';

export const freeConsultationId = 'home-free-consultation';

function Banner() {
  const handleStartFreeClick = () => scrollElementIntoView(freeConsultationId);

  return (
    <section className='banner'>
      <div className='banner-section-holder'>
        <div className='background-holder'>
          <img className='founder-image' src={founderImage} alt='Arshak Zargaryan' />
        </div>
        <div className='info-section'>
          <div className='container info-section-custom-container-style'>
            <div className='info-rows'>
              <div className='texts-wrapper'>
                <Text variant='h1' className='primaryText' fontWeight={600}>
                  ОБРАЗОВАТЕЛЬНАЯ <br />
                  ПЛАТФОРМА
                </Text>
                <Text variant='label2' className='secondaryText' fontWeight={400}>
                  Учись. Развивайся. Достигай.
                </Text>
              </div>
              <Button type='button' onClick={handleStartFreeClick}>
                Начать бесплатно
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner;
