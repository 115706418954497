import React, { useEffect, useState } from 'react';

import { animationInterval, states } from './AnimatedShape.constants';

import './styles.scss';

interface IAnimatedShapeProps {
  delay?: number;
}

const AnimatedShape: React.FC<IAnimatedShapeProps> = ({ delay = 0 }) => {
  const [animationState, setAnimationState] = useState('a');

  useEffect(() => {
    const initialDelay = setTimeout(() => {
      const interval = setInterval(() => {
        setAnimationState((prevState) => {
          switch (prevState) {
            case states.a:
              return states.b;
            case states.b:
              return states.c;
            case states.c:
              return states.a;
            default:
              return states.a;
          }
        });
      }, animationInterval);

      return () => clearInterval(interval);
    }, delay);

    return () => clearTimeout(initialDelay);
  }, [delay]);

  return (
    <div className={`animated-shape state-${animationState}`}>
      <span className='eclipse' />
      <span className='rectangle' />
    </div>
  );
};

export default AnimatedShape;
