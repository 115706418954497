import React from 'react';

import Text from '../../../ui/Text/Text';
import Icon from '../../../ui/Icon/Icon';
import useDeviceTypeDetector from '../../../hooks/useDeviceTypeDetector/useDeviceTypeDetector';

import './courseAuditorium.scss';

const CourseAuditorium = () => {
  const { isMobile } = useDeviceTypeDetector();
  return (
    <section className='course-auditorium'>
      <div className='container'>
        <div className='course-auditorium-holder'>
          <Text variant='h3' className='title' fontWeight={600}>
            ДЛЯ КОГО ЭТОТ КУРС?
          </Text>
          <div className='auditorium-types'>
            <div className='auditorium'>
              <div className='questions-holder'>
                <div className='questions-heading'>
                  <span className='heading-icon'>
                    <Icon name='sprout' size={isMobile ? 16 : 32} />
                  </span>
                  <Text variant='h3' className='heading-text' fontWeight={600}>
                    НОВИЧКИ
                  </Text>
                </div>
                <ul className='questions'>
                  <li>
                    <Text variant='body1'>Хочешь свое дело, но не знаешь, с чего начать?</Text>
                  </li>
                  <li>
                    <Text variant='body1'>Не понимаешь, чем хочешь заниматься?</Text>
                  </li>
                  <li>
                    <Text variant='body1'>
                      Боишься, что не справишься с запуском бизнеса в одиночку?
                    </Text>
                  </li>
                </ul>
              </div>
              <div className='answers-holder'>
                <Text variant='body1' className='answers-heading' fontWeight={600}>
                  С НАМИ ТЫ
                </Text>
                <ul className='answers'>
                  <li>
                    <Text variant='body1'>Выберешь нишу и упакуешь продукт</Text>
                  </li>
                  <li>
                    <Text variant='body1'>Заработаешь первые деньги и вырастешь в доходе</Text>
                  </li>
                  <li>
                    <Text variant='body1'>
                      Получишь персональное сопровождение на всех этапах: от старта до
                      масштабирования
                    </Text>
                  </li>
                </ul>
              </div>
            </div>
            <div className='auditorium'>
              <div className='questions-holder'>
                <div className='questions-heading'>
                  <span className='heading-icon'>
                    <Icon name='tree' size={isMobile ? 16 : 32} />
                  </span>
                  <Text variant='h3' className='heading-text' fontWeight={600}>
                    ОПЫТНЫЕ
                  </Text>
                </div>
                <ul className='questions'>
                  <li>
                    <Text variant='body1'>
                      Ты вкалываешь без выходных, но не видишь результата?
                    </Text>
                  </li>
                  <li>
                    <Text variant='body1'>
                      Твоя прибыль не растет, и ты не знаешь, как сдвинуться с места?
                    </Text>
                  </li>
                  <li>
                    <Text variant='body1'>Хочешь выйти на новый уровень, но не понимаешь как?</Text>
                  </li>
                </ul>
              </div>
              <div className='answers-holder'>
                <Text variant='body1' className='answers-heading' fontWeight={600}>
                  С НАМИ ТЫ
                </Text>
                <ul className='answers'>
                  <li>
                    <Text variant='body1'>
                      Найдешь точки роста и получишь новые инструменты для заработка
                    </Text>
                  </li>
                  <li>
                    <Text variant='body1'>Получишь свежие идеи для развития своего бизнеса</Text>
                  </li>
                  <li>
                    <Text variant='body1'>Масштабируешь бизнес и увеличишь доход</Text>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CourseAuditorium;
