import React from 'react';

import Text from '../../ui/Text/Text';

import EnrollmentForm from './EnrollmentForm/EnrollmentForm';

import './enrollCourse.scss';

const EnrollCourse: React.FC<{ id?: string; title?: string }> = ({ id, title }) => (
  <section className='container enroll-course-section' id={id}>
    {title && (
      <Text variant='h3' fontWeight={600} className='title'>
        {title}
      </Text>
    )}
    <div className='enroll-course-holder'>
      <div className='details'>
        <Text variant='h3' fontWeight={600} className='heading'>
          ЗАПИШИСЬ НА БИЗНЕС-КЭМП
        </Text>
        <Text variant='subtitle3' className='name' fontWeight={500}>
          БИЗНЕС-КЭМП ПРОДВИНУТЫЙ
        </Text>
        <Text variant='h2' className='actual-price' fontWeight={500}>
          33 333 ₽
        </Text>
        <Text variant='subtitle2' className='discount-price' fontWeight={300}>
          66 666 ₽
        </Text>
      </div>
      <EnrollmentForm />
    </div>
  </section>
);

export default EnrollCourse;
