import React from 'react';

import Banner, { freeConsultationId } from './components/Banner/Banner';
import BusinessCourses from './components/BusinessCourses/BusinessCourses';
import Tools from '../../components/Tools/Tools';
import Comfortability from '../../components/Comfortability/Comfortability';
import FreeConsultation from '../../components/FreeConsultation/FreeConsultation';
import WeProvideConditions from '../../components/WeProvideConditions/WeProvideConditions';
import Experts from './components/Experts/Experts';
import YourFuture from './components/YourFuture/YourFuture';
import Team from './components/Team/Team';
import Carousel from '../../components/Carousel/Carousel';

import { useScrollToTopOnMount } from '../../hooks/useScrollToTopOnMount';

function Home() {
  useScrollToTopOnMount();

  return (
    <main className='home-page page'>
      <Banner />
      <BusinessCourses />
      <Tools />
      <FreeConsultation
        id={freeConsultationId}
        title='НЕ ЗНАЕШЬ С ЧЕГО НАЧАТЬ? '
        subTitle='ЗАПИШИСЬ НА БЕСПЛАТНУЮ КОНСУЛЬТАЦИЮ'
        description='Найди точки своего роста и составь пошаговый план увеличения дохода вместе с нашим экспертом'
      />
      <Comfortability />
      <WeProvideConditions />
      <Experts />
      <YourFuture />
      <Team />
      <Carousel />
      <FreeConsultation
        title='НАЧНИ ПРЯМО СЕЙЧАС'
        subTitle='ЗАПИШИСЬ НА БЕСПЛАТНУЮ КОНСУЛЬТАЦИЮ'
        description='Найди точки своего роста и составь пошаговый план увеличения дохода вместе с нашим экспертом'
      />
    </main>
  );
}

export default Home;
