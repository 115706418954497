import React from 'react';

const CheckSVG = ({ color }: { color: string }) => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M5.5 10.0862L8.44093 13L14.5 7'
      stroke={color}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default CheckSVG;
