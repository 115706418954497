import React from 'react';
import { NavLink } from 'react-router-dom';

import Text from '../../../../ui/Text/Text';
import Button from '../../../../ui/Button/Button';
import ArrowButton from '../../../../ui/ArrowButton/ArrowButton';

import { AppRoutes } from '../../../../utils/constants';
import { CourseEnum } from '../../../CourseDetails/CourseDetails.enum';

import './businessCourses.scss';

type TOption = {
  buttonLabel: string;
  description: string;
  to: string;
};

const options: TOption[] = [
  {
    buttonLabel: 'Бизнес-кэмп экспресс',
    description: 'Запусти бизнес с нуля или увеличь доход в своем деле за 1 месяц',
    to: `${AppRoutes.courses}/${CourseEnum.Express}`,
  },
  {
    buttonLabel: 'Бизнес-кэмп базовый',
    description: 'Запусти бизнес с нуля или увеличь доход в своем деле за 2 месяц',
    to: `${AppRoutes.courses}/${CourseEnum.Basic}`,
  },
  {
    buttonLabel: 'Бизнес-кэмп продвинутый',
    description: 'Запусти бизнес с нуля или увеличь доход в своем деле за 3 месяц',
    to: `${AppRoutes.courses}/${CourseEnum.Advanced}`,
  },
];

const BusinessCourses = () => (
  <section className='business-courses'>
    <div className='container'>
      <div className='business-courses-holder'>
        <Text variant='h3' className='section-header' fontWeight={600}>
          НАШИ БИЗНЕС-КУРСЫ
        </Text>
        <div className='courses-grid'>
          {options.map((item) => {
            const { buttonLabel, description, to } = item;
            return (
              <NavLink to={to} key={`${buttonLabel}`} className='course-item'>
                <Button variant='primary'>Бизнес-кэмп базовый</Button>
                <Text variant='body1' className='description'>
                  {description}
                </Text>
                <ArrowButton />
              </NavLink>
            );
          })}
        </div>
      </div>
    </div>
  </section>
);

export default BusinessCourses;
