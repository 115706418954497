import React, { useLayoutEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import cn from 'classnames';

import { APP_ID, AppRoutes } from './utils/constants';

import Home from './pages/Home/Home';
import Courses from './pages/Courses/Courses';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import CourseDetails from './pages/CourseDetails/CourseDetails';
import FreeConsultationPage from './pages/FreeConsultationPage/FreeConsultationPage';
import EntrepreneurSchool from './pages/EntrepreneurSchool/EntrepreneurSchool';
import useDeviceTypeDetector from './hooks/useDeviceTypeDetector/useDeviceTypeDetector';

import './assets/styles/index.scss';

function App() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { deviceType } = useDeviceTypeDetector();

  const setBodyClass = () => document.body.setAttribute('class', deviceType);

  useLayoutEffect(setBodyClass, [deviceType]);

  return (
    <div id={APP_ID} className={cn('application', isDrawerOpen && 'drawer-open')}>
      <Header isDrawerOpen={isDrawerOpen} onDrawerOpenChange={setIsDrawerOpen} />
      <Routes>
        <Route path={AppRoutes.home} element={<Home />} />
        <Route path={AppRoutes.courses} element={<Courses />} />
        <Route path={AppRoutes.school} element={<EntrepreneurSchool />} />
        <Route path={`${AppRoutes.courses}/:course`} element={<CourseDetails />} />
        <Route path={AppRoutes.freeConsultation} element={<FreeConsultationPage />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
