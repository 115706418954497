import {ICoursePacketProps} from './CoursePacket/CoursePacket.types';
import {CourseEnum} from "../../pages/CourseDetails/CourseDetails.enum";

export const expressCoursePacket: ICoursePacketProps = {
    id: '1',
    type: 'express',
    name: 'БИЗНЕС-КЭМП ЭКСПРЕСС',
    price: 22222,
    discountPrice: 11111,
    meetsCount: 4,
    description: 'Запусти бизнес с нуля или увеличь доход в своем деле за 1 месяц',
    result: 'Заработаешь',
    resultHighlight: 'от 50 000 ₽ за 1 месяц',
    goals: [
        {
            isActive: true,
            label: 'Сформируешь цели',
        },
        {
            isActive: true,
            label: 'Выберешь свое дело',
        },
        {
            isActive: true,
            label: 'Проанализируешь рынок',
        },
        {
            isActive: true,
            label: 'Разработаешь MVP',
        },
        {
            isActive: true,
            label: 'Подберешь команду',
        },
        {
            isActive: true,
            label: 'Создашь точки контакта',
        },
        {
            isActive: false,
            label: 'Сформируешь инфраструктуру бизнеса',
        },
        {
            isActive: false,
            label: 'Настроишь клиентский поток',
        },
        {
            isActive: false,
            label: 'Упакуешь полноценный продукт',
        },
        {
            isActive: false,
            label: 'Построишь систему продаж',
        },
        {
            isActive: false,
            label: 'Сформируешь бизнес-систему',
        },
        {
            isActive: false,
            label: 'Масштабируешь бизнес',
        },
    ],
    coursePath: CourseEnum.Express
};

export const basicCoursePacket: ICoursePacketProps = {
    id: '2',
    type: 'basic',
    size: 'big',
    name: 'БИЗНЕС-КЭМП БАЗОВЫЙ',
    price: 44444,
    discountPrice: 22222,
    meetsCount: 8,
    description: 'Запусти бизнес с нуля или увеличь доход в своем деле за 2 месяца',
    result: 'Заработаешь',
    resultHighlight: 'от 100 000 ₽ за 2 месяца',
    goals: [
        {
            isActive: true,
            label: 'Сформируешь цели',
        },
        {
            isActive: true,
            label: 'Выберешь свое дело',
        },
        {
            isActive: true,
            label: 'Проанализируешь рынок',
        },
        {
            isActive: true,
            label: 'Разработаешь MVP',
        },
        {
            isActive: true,
            label: 'Подберешь команду',
        },
        {
            isActive: true,
            label: 'Создашь точки контакта',
        },
        {
            isActive: true,
            label: 'Сформируешь инфраструктуру бизнеса',
        },
        {
            isActive: true,
            label: 'Настроишь клиентский поток',
        },
        {
            isActive: true,
            label: 'Упакуешь полноценный продукт',
        },
        {
            isActive: false,
            label: 'Построишь систему продаж',
        },
        {
            isActive: false,
            label: 'Сформируешь бизнес-систему',
        },
        {
            isActive: false,
            label: 'Масштабируешь бизнес',
        },
    ],
    coursePath: CourseEnum.Basic
};

export const advancedCoursePacket: ICoursePacketProps = {
    id: '3',
    type: 'advanced',
    name: 'БИЗНЕС-КЭМП ПРОДВИНУТЫЙ',
    price: 66666,
    discountPrice: 33333,
    meetsCount: 12,
    description: 'Запусти бизнес с нуля или увеличь доход в своем деле за 3 месяца',
    result: 'Заработаешь',
    resultHighlight: 'от 150 000 ₽ за 3 месяца',
    goals: [
        {
            isActive: true,
            label: 'Сформируешь цели',
        },
        {
            isActive: true,
            label: 'Выберешь свое дело',
        },
        {
            isActive: true,
            label: 'Проанализируешь рынок',
        },
        {
            isActive: true,
            label: 'Разработаешь MVP',
        },
        {
            isActive: true,
            label: 'Подберешь команду',
        },
        {
            isActive: true,
            label: 'Создашь точки контакта',
        },
        {
            isActive: true,
            label: 'Сформируешь инфраструктуру бизнеса',
        },
        {
            isActive: true,
            label: 'Настроишь клиентский поток',
        },
        {
            isActive: true,
            label: 'Упакуешь полноценный продукт',
        },
        {
            isActive: true,
            label: 'Построишь систему продаж',
        },
        {
            isActive: true,
            label: 'Сформируешь бизнес-систему',
        },
        {
            isActive: true,
            label: 'Масштабируешь бизнес',
        },
    ],
    coursePath: CourseEnum.Advanced
};

export const coursePacketsData: ICoursePacketProps[] = [
    expressCoursePacket,
    basicCoursePacket,
    advancedCoursePacket,
];
