export const UpcomingStepsData = [
    {
        stepNumber: 1,
        title: 'Формирование целей',
        stepTargets: ['Определишь цель и сформируешь план действий', 'Прокачаешь мышление предпринимателя', 'Проработаешь ключевые навыки предпринимателя'],
        result: 'Сформулируешь свои цели'
    },
    {
        stepNumber: 2,
        title: 'Выбор своего дела',
        stepTargets: ['Проанализируешь свои интересы, навыки и компетенции', 'Узнаешь, как найти бизнес-идею', 'Узнаешь, как протестировать бизнес-идею'],
        result: 'Выберешь свое дело'
    },
    {
        stepNumber: 3,
        title: 'Анализ рынка',
        stepTargets: ['Узнаешь, как оценить объем рынка', 'Узнаешь, как оценить конкурентов', 'Узнаешь, как оценить потребителей'],
        result: 'Проанализируешь свои рынки'
    },
    {
        stepNumber: 4,
        title: 'Разработка MVP',
        stepTargets: ['Узнаешь, как создать продукт с минимальным функционалом', 'Узнаешь, как рассчитать юнит-экономику', 'Узнаешь, как избежать ошибок на старте'],
        result: 'Разработаешь свой MVP'
    },
    {
        stepNumber: 5,
        title: 'Подбор команды',
        stepTargets: ['Начнешь искать и нанимать сотрудников', 'Научишься руководить персоналом', 'Узнаешь, как создать результативный личный бренд'],
        result: 'Подберешь свою команду'
    },
    {
        stepNumber: 6,
        title: 'Создание точек контакта',
        stepTargets: ['Создашь точки контакта клиентов с продуктом', 'Разработаешь план маркетинга', 'Начнешь привлекать клиентов'],
        result: 'Создашь свои точки контакта'
    }
]