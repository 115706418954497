import {IExpertProps} from "./Expert/Expert.types";

import expert_1 from '../../../assets/media/experts/expert_1.png';

export const expertsData: IExpertProps[] = [{
    hint: 'Действуй вместе с нами!',
    img: {src: expert_1, alt: 'Expert'},
    name: 'Аршак Заргарян',
    footNote: 'Поможем открыть собственный бизнес',
    skills: [{id: 0, text: 'кандидат экономических наук, специализация «малое предпринимательство»'}, {
        id: 1,
        text: 'опыт финансирования бизнес-проектов (16 лет) '
    }, {id: 2, text: 'опыт работы со студентами по запуску бизнес-проектов (11 лет)'}, {
        id: 3,
        text: 'высокая мотивация на результат и контроль за прогрессом'
    }, {
        id: 4,
        text: '«великие дела нужно совершать, а не обдумывать бесконечно»'
    }]
}]