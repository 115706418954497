import React from 'react';

import Step from './Step/Step';

import { SnakeStepsData } from './SnakeSteps.constants';

import './snakeSteps.scss';

const SnakeSteps = () => (
    <div className='snake-steps'>
      <div className='snake-steps-holder'>
        {SnakeStepsData.map((step, i) => (
          <Step key={i} {...step} />
        ))}
      </div>
    </div>
  );

export default SnakeSteps;
