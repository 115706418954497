import React from 'react';

import Hero from './Hero/Hero';
import SnakeSteps from './SnakeSteps/SnakeSteps';
import AnimationLayer from './AnimationLayer/AnimationLayer';

const AnimationSection = () => (
  <section className='animation-section'>
    <AnimationLayer />
    <Hero />
    <SnakeSteps />
  </section>
);

export default AnimationSection;
