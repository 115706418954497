import React from 'react';

import Text from '../../../../ui/Text/Text';
import Icon from '../../../../ui/Icon/Icon';
import CheckSVG from '../../../../ui/svg/CheckSVG';

import { CheckIconColorMap } from './CourseGoals.constants';
import { TCoursePacketType, TGoal } from '../CoursePacket.types';

import './courseGoals.scss';

interface IProsAndConsProps {
  goals: TGoal[];
  type?: TCoursePacketType;
}

const CourseGoals: React.FC<IProsAndConsProps> = ({ goals, type = 'express' }) => {
  if (!goals?.length) return null;

  return (
    <ul className='course-goals'>
      {goals?.map((goal, i) => (
        <li key={`${i}`} className={`goal ${goal.isActive ? 'goal-active' : ''}`}>
          {goal.isActive ? (
            <CheckSVG color={CheckIconColorMap[type]} />
          ) : (
            <Icon name='no' size={20} />
          )}
          <Text isEllipsis variant='body1' fontWeight={500} className='goal-text'>
            {goal.label}
          </Text>
        </li>
      ))}
    </ul>
  );
};

export default CourseGoals;
