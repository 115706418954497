import {CourseEnum} from "./CourseDetails.enum";

export const CourseData = {
    [CourseEnum.Express]: {
        name: 'БИЗНЕС-КЭМП ЭКСПРЕСС',
        shortName: 'ЭКСПРЕСС'
    },
    [CourseEnum.Basic]: {
        name: 'БИЗНЕС-КЭМП БАЗОВЫЙ',
        shortName: 'БАЗОВЫЙ'
    },
    [CourseEnum.Advanced]: {
        name: 'БИЗНЕС-КЭМП ПРОДВИНУТЫЙ',
        shortName: 'ПРОДВИНУТЫЙ'
    }
};