import React from 'react';

import Tools from '../../components/Tools/Tools';
import OurExperts from './OurExperts/OurExperts';
import OwnBusiness from './OwnBusiness/OwnBusiness';
import BusinessPhases from './BusinessPhases/BusinessPhases';
import AnimationSection from './AnimationSection/AnimationSection';
import CoursePackets from '../../components/CoursePackets/CoursePackets';
import Comfortability from '../../components/Comfortability/Comfortability';
import FreeConsultation from '../../components/FreeConsultation/FreeConsultation';
import WeProvideConditions from '../../components/WeProvideConditions/WeProvideConditions';

import { freeConsultationId } from './AnimationSection/Hero/Hero';
import { useScrollToTopOnMount } from '../../hooks/useScrollToTopOnMount';

import './entrepreneurSchool.scss';

const EntrepreneurSchool = () => {
  useScrollToTopOnMount();

  return (
    <main className='school-page page'>
      <AnimationSection />
      <Tools />
      <FreeConsultation
        id={freeConsultationId}
        title='НЕ ЗНАЕШЬ С ЧЕГО НАЧАТЬ?'
        subTitle='ЗАПИШИСЬ НА БЕСПЛАТНУЮ КОНСУЛЬТАЦИЮ'
        description='Найди точки своего роста и составь пошаговый план увеличения дохода вместе с нашим экспертом'
      />
      <CoursePackets />
      <OurExperts />
      <OwnBusiness />
      <BusinessPhases />
      <FreeConsultation
        title='НАЧНИ ПРЯМО СЕЙЧАС'
        subTitle='ЗАПИШИСЬ НА БЕСПЛАТНУЮ КОНСУЛЬТАЦИЮ'
        description='Найди точки своего роста и составь пошаговый план увеличения дохода вместе с нашим экспертом'
      />
      <Comfortability />
      <WeProvideConditions />
    </main>
  );
};

export default EntrepreneurSchool;
