import {TBusinessTarget} from "./BusinessTarget/BusinessTarget";

export const businessTargetsData: TBusinessTarget[] = [
    {
        title: 'Пройди обучение',
        iconName: 'book',
        description: 'Не бойся того, что не получится, бойся того, что не пробуешь.'
    },
    {
        title: 'Поставь бизнес-цели',
        iconName: 'target',
        description: 'Определись со своими бизнес-целями, нарисуй кем ты себя видишь через год.'
    },
    {
        title: 'Найди то, что подходит тебе',
        iconName: 'keywordResearch',
        description: 'Будь открыт, не бойся задавать вопросы, это поможет тебе найти свое дело.'
    },
    {
        title: 'Проверь свою гипотезу',
        iconName: 'bulb',
        description: 'Протестируй свои идеи, прежде чем начать рисковать. Ты сэкономишь свое время и деньги.'
    },
    {
        title: 'Запусти первые продажи',
        iconName: 'investment',
        description: 'Определи боли своего клиента, предложи решение. Он это оценит, а ты заработаешь.'
    },
    {
        title: 'Закрой жизненные цели',
        iconName: 'stairs',
        description: 'Достигни целей в бизнесе, реализуй свои жизненные цели, исполни мечты.'
    }
]