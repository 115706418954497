import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { createPortal } from 'react-dom';
import { NavLink } from 'react-router-dom';

import Icon from '../../../ui/Icon/Icon';
import Text from '../../../ui/Text/Text';

import { ROOT_ID } from '../../../utils/constants';

import './drawerMenu.scss';

interface IDrawerMenuProps {
  isDrawerOpen: boolean;
  onClose: () => void;
}

const DrawerMenu = ({ isDrawerOpen, onClose }: IDrawerMenuProps) => {
  const [isClosedWithDelay, setIsClosedWithDelay] = useState(!isDrawerOpen);

  useEffect(() => {
    if (isDrawerOpen) {
      setIsClosedWithDelay(false);
    } else {
      setTimeout(() => setIsClosedWithDelay(true), 300);
    }
  }, [isDrawerOpen]);

  const navLinkClassName = ({ isActive }: { isActive: boolean }) =>
    `nav-link ${isActive ? 'nav-link-active' : ''}`;

  return (
    <>
      {createPortal(
        <div
          className={cn(
            'drawer-menu',
            isDrawerOpen && 'drawer-menu-open',
            isClosedWithDelay && 'drawer-menu-closed'
          )}
        >
          <div className='drawer-menu-holder'>
            <button className='close-button' aria-label='Close button' onClick={onClose}>
              <Icon name='close' size={24} />
              <Text variant='subtitle3'>Назад</Text>
            </button>
            <nav className='nav-menu'>
              <NavLink to='/' className={navLinkClassName} onClick={onClose}>
                <Text variant='label1' fontWeight={500}>
                  Главная
                </Text>
              </NavLink>
              <NavLink to='/entrepreneur-school' className={navLinkClassName} onClick={onClose}>
                <Text variant='label1' fontWeight={500}>
                  Школа предпринимателя
                </Text>
              </NavLink>
              <NavLink to='/courses' className={navLinkClassName} onClick={onClose}>
                <Text variant='label1' fontWeight={500}>
                  Курсы
                </Text>
              </NavLink>
              <NavLink to='/free-consultation' className={navLinkClassName} onClick={onClose}>
                <Text variant='label1' fontWeight={500}>
                  Бесплатная консультация
                </Text>
              </NavLink>
            </nav>
          </div>
        </div>,
        document.getElementById(ROOT_ID)!
      )}
      {/* {isDrawerOpen && */}
      {/*  createPortal( */}
      {/*    <button */}
      {/*      aria-label='overlay' */}
      {/*      className='drawer-menu-overlay' */}
      {/*      onClick={handleOverlayClick} */}
      {/*    />, */}
      {/*    document.getElementById(APP_ID)! */}
      {/*  )} */}
    </>
  );
};

export default DrawerMenu;
