import React from 'react';
import cn from 'classnames';

import Text from '../../ui/Text/Text';
import ConsultationForm from './ConsultationForm/ConsultationForm';
import useDeviceTypeDetector from '../../hooks/useDeviceTypeDetector/useDeviceTypeDetector';

import { ConsultationSteps } from './FreeConsultation.constants';

import './freeConsultation.scss';

const FreeConsultation: React.FC<{
  id?: string;
  title?: string;
  subTitle?: string;
  description?: string;
}> = ({ id, title, subTitle, description }) => {
  const { isDesktop, isMobile } = useDeviceTypeDetector();

  const iconIndex = isDesktop ? 2 : isMobile ? 0 : 1;

  const sectionClassName = cn('free-consultation-section', {
    'with-description': !!description,
    'with-title': !!title,
  });

  return (
    <section id={id} className={sectionClassName}>
      <div className='container '>
        {title && (
          <Text variant='h3' fontWeight={600} className='title'>
            {title}
          </Text>
        )}
        <div className='free-consultation-holder'>
          <div className='details'>
            <div className='texts'>
              {subTitle && (
                <Text variant='h3' fontWeight={600} className='heading'>
                  {subTitle}
                </Text>
              )}
              {description && (
                <Text variant='subtitle3' className='description'>
                  {description}
                </Text>
              )}
            </div>
            <div className='steps'>
              {ConsultationSteps.map((step, i) => (
                <div key={i} className='step'>
                  <img src={step.icon[iconIndex]} alt={`Consultation step ${i}`} />
                  <Text variant='subtitle2'>{step.text}</Text>
                </div>
              ))}
            </div>
          </div>
          <ConsultationForm />
        </div>
      </div>
    </section>
  );
};

export default FreeConsultation;
