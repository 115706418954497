import {TIconSize} from "./Icon.types";

export const IconSizeMap: Record<TIconSize, string> = {
    16: 'icon_16',
    20: 'icon_20',
    24: 'icon_24',
    32: 'icon_32',
    36: 'icon_36',
    40: 'icon_40',
    56: 'icon_56',
    64: 'icon_64'
}