import React from 'react';

import Tools from '../../components/Tools/Tools';
import OurAuditorium from './OurAuditorium/OurAuditorium';
import CoursePackets from '../../components/CoursePackets/CoursePackets';
import FreeConsultation from '../../components/FreeConsultation/FreeConsultation';
import { useScrollToTopOnMount } from '../../hooks/useScrollToTopOnMount';

const Courses = () => {
  useScrollToTopOnMount();

  return (
    <main className='page'>
      <OurAuditorium />
      <Tools />
      <CoursePackets />
      <FreeConsultation
        title='НАЧНИ ПРЯМО СЕЙЧАС'
        subTitle='ЗАПИШИСЬ НА БЕСПЛАТНУЮ КОНСУЛЬТАЦИЮ'
        description='Найди точки своего роста и составь пошаговый план увеличения дохода вместе с нашим экспертом'
      />
    </main>
  );
};

export default Courses;
