import React from 'react';
import './experts.scss';
import Text from '../../../../ui/Text/Text';
import expertsImage from '../../../../assets/media/experts-image.png';

type TConfigItem = {
  title: string;
  description: string;
};

const config: TConfigItem[] = [
  {
    title: 'Профильное образование',
    description: 'Все эксперты обладают высшим профильным образованием лучших университетов.',
  },
  {
    title: 'Методика обучения',
    description:
      'Обучение построено по практической методике. Ты начнешь практиковаться на первом занятии.',
  },
  {
    title: 'Практический опыт',
    description:
      'Каждый эксперт имеет практический опыт в своей нише, может поделиться своими успехами и неудачами.',
  },
];

const Experts = () => (
  <section className='experts'>
    <div className='container'>
      <div className='experts-holder'>
        <Text variant='h3' fontWeight={600} className='title'>
          Отбираем лучших экспертов
        </Text>
        <div className='experts-widget-wrapper'>
          <div className='experts-rows'>
            {config.map((item: TConfigItem, index) => (
              <div className='row-item' key={index}>
                <p className='title'>{item.title}</p>
                <p className='description'>{item.description}</p>
              </div>
            ))}
          </div>
          <img src={expertsImage} alt='' className='section-image' />
        </div>
      </div>
    </div>
  </section>
);

export default Experts;
