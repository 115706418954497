import React from 'react';

import Text from '../../../ui/Text/Text';
import Icon from '../../../ui/Icon/Icon';
import useDeviceTypeDetector from '../../../hooks/useDeviceTypeDetector/useDeviceTypeDetector';

import { UpcomingStepsData } from './UpcomingSteps.constants';

import './upcomingSteps.scss';

const UpcomingSteps = () => {
  const { isLaptop, isDesktop } = useDeviceTypeDetector();

  const sliceIndex = isDesktop ? UpcomingStepsData.length : isLaptop ? 4 : 2;

  return (
    <section className='upcoming-steps'>
      <div className='container'>
        <div className='upcoming-steps-holder'>
          <Text variant='h3' className='title' fontWeight={600}>
            ЧТО ТЕБЯ ЖДЕТ?
          </Text>
          <div className='steps'>
            {UpcomingStepsData.slice(0, sliceIndex).map((step) => (
              <div key={step.stepNumber} className='step'>
                <Text
                  variant='body1'
                  fontWeight={600}
                  className='label'
                >{`Модуль ${step.stepNumber}`}</Text>
                <div className='targets-holder'>
                  <Text variant='subtitle1' className='step-title'>
                    {step.title}
                  </Text>
                  <ul>
                    {step.stepTargets.map((target, i) => (
                      <li key={`${i}`}>
                        <Text variant='body1'>{target}</Text>
                      </li>
                    ))}
                  </ul>
                </div>
                <Text variant='body1' fontWeight={600} className='result-label'>
                  Твой результат:
                </Text>
                <Text variant='body1' className='result-text'>
                  {step.result}
                </Text>
              </div>
            ))}
          </div>
          <a href='/' className='view-all-modules'>
            <Text variant='body1' fontWeight={500}>
              Все Модули
            </Text>
            <Icon name='chevronRight' size={16} />
          </a>
        </div>
      </div>
    </section>
  );
};

export default UpcomingSteps;
