import { useEffect, useRef, useState } from 'react';

import { DeviceTypeEnum } from '../../utils/enums';
import { getDeviceType } from '../../utils/helpers';

type DeviceInfo = {
  isMobile: boolean;
  isTablet: boolean;
  isLaptop: boolean;
  isDesktop: boolean;
  isDesktopSmall: boolean;
  isDesktopLarge: boolean;
  deviceType: DeviceTypeEnum;
};

const useDeviceTypeDetector = () => {
  const timeoutId = useRef<NodeJS.Timeout>();
  const initialDeviceType = getDeviceType(window.innerWidth);

  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>({
    deviceType: initialDeviceType,
    isMobile: initialDeviceType === DeviceTypeEnum.Mobile,
    isTablet: initialDeviceType === DeviceTypeEnum.Tablet,
    isLaptop: initialDeviceType === DeviceTypeEnum.Laptop,
    isDesktop:
      initialDeviceType === DeviceTypeEnum.DesktopSmall ||
      initialDeviceType === DeviceTypeEnum.DesktopLarge,
    isDesktopSmall: initialDeviceType === DeviceTypeEnum.DesktopSmall,
    isDesktopLarge: initialDeviceType === DeviceTypeEnum.DesktopLarge,
  });

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      const deviceType = getDeviceType(windowWidth);

      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }

      timeoutId.current = setTimeout(() => {
        setDeviceInfo({
          deviceType,
          isMobile: deviceType === DeviceTypeEnum.Mobile,
          isTablet: deviceType === DeviceTypeEnum.Tablet,
          isLaptop: deviceType === DeviceTypeEnum.Laptop,
          isDesktop:
            deviceType === DeviceTypeEnum.DesktopSmall ||
            deviceType === DeviceTypeEnum.DesktopLarge,
          isDesktopSmall: deviceType === DeviceTypeEnum.DesktopSmall,
          isDesktopLarge: deviceType === DeviceTypeEnum.DesktopLarge,
        });
      }, 300);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return deviceInfo;
};

export default useDeviceTypeDetector;
