import React from 'react';

import Text from '../../../../ui/Text/Text';

import './styles.scss';

function YourFuture() {
  return (
    <section className='your-future'>
      <Text variant='h3' fontWeight={600} className='title'>
        Ты сам выбираешь свое будущее
      </Text>
      <div className='your-future-holder'>
        <div className='holder-item'>
          <Text variant='label2' className='holder-item-text'>
            Не сиди дома,
            <br /> обесценивая себя
          </Text>
        </div>
        <div className='holder-item'>
          <Text variant='label2' className='holder-item-text'>
            Не забивай голову
            <br /> успехами других
          </Text>
        </div>
        <div className='holder-item'>
          <Text variant='label2' className='holder-item-text'>
            Проживай свою
            <br /> собственную жизнь
          </Text>
        </div>
      </div>
    </section>
  );
}

export default YourFuture;
