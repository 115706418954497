export const configA = {
    heading: 'Экономим время',
    rows: [
        {
            title: 'Не теряй время на дорогу',
            description:
                'Используй свое время эффективно – не нужно ездить на курсы, они сами приедут к тебе.',
        },
        {
            title: 'Учись откуда удобно',
            description: 'Работа, дом, дача – наши эксперты там, где ты.',
        },
    ],
};

export const configB = {
    heading: 'Мотивируем на результат',
    rows: [
        {
            title: 'Настраивайся на результат',
            description: 'С мотивацией нашей команды и заряжающей энергией группы у тебя все получится.',
        },
        {
            title: 'Следи за своим прогрессом',
            description: 'Контроль твоих домашних заданий и обратная связь по твоему прогрессу.',
        },
    ],
};

export const configC = {
    heading: 'Экономим бюджет',
    rows: [
        {
            title: 'Тебе не нужны учебные материалы',
            description: 'Для занятий нужен только Интернет и 120 минут твоего времени.',
        },
        {
            title: 'Курсы по бюджету',
            description:
                'Мы предлагаем курсы с разным бюджетом, чтобы ты выбрал наиболее подходящий тебе.',
        },
    ],
};