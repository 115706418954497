import React from 'react';

import Text from '../../../../ui/Text/Text';
import Button from '../../../../ui/Button/Button';

import { scrollElementIntoView } from '../../../../utils/helpers';

import useDeviceTypeDetector from '../../../../hooks/useDeviceTypeDetector/useDeviceTypeDetector';

import './hero.scss';

export const freeConsultationId = 'school-free-consultation';

const Hero = () => {
  const { isDesktop } = useDeviceTypeDetector();

  const handleStartFreeClick = () => scrollElementIntoView(freeConsultationId);
  return (
    <div className='hero container'>
      <Text variant='h2' className='title' fontWeight={600}>
        ШКОЛА ПРЕДПРИНИМАТЕЛЯ
      </Text>
      <Text variant='subtitle1' className='subtitle'>
        с нами ты откроешь свой бизнес
      </Text>
      <Button
        type='button'
        variant='secondary'
        className='button'
        size={isDesktop ? 'large' : 'small'}
        onClick={handleStartFreeClick}
      >
        <Text variant='body1' fontWeight={600}>
          Начать бесплатно
        </Text>
      </Button>
    </div>
  );
};

export default Hero;
