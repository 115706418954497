import React from 'react';

import Icon from '../../../../../ui/Icon/Icon';
import Text from '../../../../../ui/Text/Text';
import useDeviceTypeDetector from '../../../../../hooks/useDeviceTypeDetector/useDeviceTypeDetector';

import { IStepProps } from './Step.types';

const Step: React.FC<IStepProps> = ({ iconName, text }) => {
  const { isDesktop, isMobile } = useDeviceTypeDetector();

  return (
    <div className='snake-step'>
      <Icon name={iconName} size={isDesktop ? 64 : isMobile ? 24 : 56} />
      <Text variant={isMobile ? 'body1' : 'subtitle2'} fontWeight={500}>
        {text}
      </Text>
    </div>
  );
};

export default Step;
