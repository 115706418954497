import React from 'react';

import Text from '../../../../ui/Text/Text';
import Icon from '../../../../ui/Icon/Icon';

import { TIconName } from '../../../../ui/Icon/Icon.types';

import './businessTarget.scss';
import useDeviceTypeDetector from '../../../../hooks/useDeviceTypeDetector/useDeviceTypeDetector';

export type TBusinessTarget = {
  title: string;
  description: string;
  iconName: TIconName;
};

const BusinessTarget: React.FC<TBusinessTarget> = ({ iconName, title, description }) => {
  const { isTablet, isMobile } = useDeviceTypeDetector();

  return (
    <div className='business-target'>
      <div className='target-heading'>
        <Text variant='subtitle1' className='title' fontWeight={600}>
          {title}
        </Text>
        <Icon name={iconName} size={isTablet || isMobile ? 24 : 40} />
      </div>
      <Text variant='body1' className='description'>
        {description}
      </Text>
    </div>
  );
};

export default BusinessTarget;
