import React from 'react';
import {Link} from "react-router-dom";

import Text from '../../ui/Text/Text';
import ArrowButton from '../../ui/ArrowButton/ArrowButton';
import {AppRoutes} from "../../utils/constants";

import './tools.scss';

const Tools = () => (
  <section className='tools-section'>
    <div className='container'>
      <Text variant='h3' fontWeight={600} className='title'>
        НАШ ИНСТРУМЕНТ
      </Text>
      <div className='tools-holder'>
        <span className='hint'>
          <Text variant='body1' fontWeight={600}>
            Онлайн / 60 минут
          </Text>
        </span>
        <div className='rows'>
          <Text variant='subtitle1'>БЕСПЛАТНАЯ КОНСУЛЬТАЦИЯ</Text>
          <Text variant='subtitle2'>
            Найди точки своего роста и составь пошаговый план увеличения дохода вместе с нашим
            экспертом
          </Text>
        </div>
        <Link to={AppRoutes.freeConsultation} className='link-button'>
          <ArrowButton />
        </Link>
      </div>
    </div>
  </section>
);

export default Tools;
