import React from 'react';

import Text from '../../../ui/Text/Text';
import useDeviceTypeDetector from '../../../hooks/useDeviceTypeDetector/useDeviceTypeDetector';

import heroImg from '../../../assets/media/advanced-camp.png';
import heroImgSmall from '../../../assets/media/advanced-camp-small.png';

import './courseHero.scss';

const CourseHero = () => {
  const { isMobile } = useDeviceTypeDetector();

  return (
    <section className='course-hero'>
      <div className='container'>
        <div className='course-hero-holder'>
          <Text variant='h2' className='title' fontWeight={600}>
            БИЗНЕС-КЭМП ПРОДВИНУТЫЙ
          </Text>
          <Text variant='subtitle1' className='sub-title'>
            Запусти бизнес с нуля или увеличь доход в своем деле за 3 месяца
          </Text>
          <div className='course-hero-image'>
            <img src={isMobile ? heroImgSmall : heroImg} alt='Advanced camp' />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CourseHero;
