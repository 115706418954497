import React from 'react';

import Text from '../../../../ui/Text/Text';

import './team.scss';

import icon1 from '../../../../assets/media/icons/noun-badge.svg';
import icon2 from '../../../../assets/media/icons/noun-computer.svg';
import icon3 from '../../../../assets/media/icons/noun-management.svg';
import sectionImageBig from '../../../../assets/media/teamSection/team-section-image.png';

type TConfigItem = {
  image: string;
  title: string;
  description: string;
  name: string;
};

const config: TConfigItem[] = [
  {
    image: icon3,
    title: 'Управление',
    description: 'Инновационные методы управления с применением цифровых технологий.',
    name: 'management',
  },
  {
    image: icon1,
    title: 'Методисты',
    description: 'В школе работают профессионалы с научно-методическим опытом.',
    name: 'methodists',
  },
  {
    image: icon2,
    title: 'Платформа',
    description: 'Платформа SkyBox и цифровые материалы обеспечивают эффективный способ обучения.',
    name: 'platform',
  },
];

function Team() {
  return (
    <section className='team'>
      <Text variant='h3' fontWeight={600} className='title'>
        Команда
      </Text>
      <div className='container'>
        <div className='team-holder'>
          <div className='image-frame'>
            {/* TODO: Add small image for smaller devices */}
            <img src={sectionImageBig} alt='' />
          </div>
          <div className='items-wrapper'>
            {config.map((item, index) => {
              const { image, title, description, name } = item;
              return (
                <div className='item' key={index}>
                  <div className={`item-image-frame ${name}`}>
                    <img src={image} className='item-image' alt='' />
                  </div>
                  <div className='texts'>
                    <p className='title'>{title}</p>
                    <p className='description'>{description}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Team;
