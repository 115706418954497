import React from 'react';
import Text from '../../../ui/Text/Text';
import checkIcon from '../../../assets/media/icons/check-icon.svg';
import './inResult.scss';

const InResult = () => (
  <section className='in-result'>
    <Text variant='h3' fontWeight={600} className='title'>
      В РЕЗУЛЬТАТЕ ТЫ:
    </Text>
    <div className='container'>
      <div className='in-result-grid'>
        <div className='in-result-item'>
          <div className='check-icon'>
            <img src={checkIcon} alt='check icon' />
          </div>
          <Text variant='subtitle3' fontWeight={400} className='label'>
            Сформируешь свои бизнес-цели
          </Text>
        </div>
        <div className='in-result-item'>
          <div className='check-icon'>
            <img src={checkIcon} alt='check icon' />
          </div>
          <Text variant='subtitle3' fontWeight={400} className='label'>
            Определишь направления запуска бизнеса
          </Text>
        </div>
        <div className='in-result-item'>
          <div className='check-icon'>
            <img src={checkIcon} alt='check icon' />
          </div>
          <Text variant='subtitle3' fontWeight={400} className='label'>
            Составишь поэтапный план действий
          </Text>
        </div>
      </div>
    </div>
  </section>
);

export default InResult;
