import React from 'react';

import InfoWidget from '../InfoWidget';

import bannerImageA from '../../../assets/media/girl-in-hoodie.png';
import bannerImageB from '../../../assets/media/banner-image-guy.png';

import { configA, configB, configC } from './constants';

export default function FeaturesWidgetMobile() {
  return (
    <div className='features-widget'>
      <div className='column'>
        <InfoWidget config={configA} styleType='typeA' className='widget2' />
        <InfoWidget config={configB} styleType='typeB' className='widget3' />
        <div className='image-frame'>
          <img src={bannerImageB} alt='' className='banner-image' />
        </div>
      </div>
      <div className='column'>
        <InfoWidget
          label='Для тех, кто хочет открыть бизнес, но не знает, с чего начать'
          className='widget1'
        />
        <div className='image-frame'>
          <img src={bannerImageA} alt='' className='banner-image' />
        </div>
        <InfoWidget config={configC} styleType='typeC' className='widget2' />
        <InfoWidget
          label='Для тех, кто открыл бизнес и хочет масштаба'
          className='widget4 customInfoWrapper1'
        />
        <InfoWidget
          label='Для тех, кто открывал бизнес, но не достиг целей'
          className='widget4 customInfoWrapper1'
        />
      </div>
    </div>
  );
}
