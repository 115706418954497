import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';

import CoursePacket from './CoursePacket/CoursePacket';
import CoursePacketsSkeleton from './CoursePacketsSkeleton/CoursePacketsSkeleton';
import useDeviceTypeDetector from '../../hooks/useDeviceTypeDetector/useDeviceTypeDetector';

import { coursePacketsData } from './CoursePackets.mocks';

import 'swiper/css';
import 'swiper/css/navigation';

import './coursePackets.scss';

const CoursePackets = () => {
  const { isDesktop, isMobile } = useDeviceTypeDetector();

  const [isLoading, setIsLoading] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout>();

  const handleLoaderOnResize = () => {
    const onSetIsLoading = () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      setIsLoading(true);

      timeoutRef.current = setTimeout(() => {
        setIsLoading(false);
      }, 500);
    };

    if (!isDesktop) {
      onSetIsLoading();

      window.addEventListener('resize', onSetIsLoading);

      return () => window.removeEventListener('resize', onSetIsLoading);
    }

    // eslint consistent-return
    return undefined;
  };

  useEffect(handleLoaderOnResize, [isDesktop]);

  return (
    <section className='course-packets-section container-desktop'>
      {isLoading ? (
        <>
          <h3 className='section-title'>НАШИ БИЗНЕС-КУРСЫ</h3>
          <CoursePacketsSkeleton />
        </>
      ) : (
        <Swiper
          observer
          pagination={isMobile}
          initialSlide={+!isDesktop}
          centeredSlides={!isDesktop}
          navigation={!isDesktop}
          spaceBetween={isMobile ? 8 : 16}
          slidesPerGroup={isDesktop ? 3 : 1}
          modules={[Navigation, Pagination]}
          slidesPerView={isDesktop ? 3 : 'auto'}
        >
          <h3 className='section-title'>НАШИ БИЗНЕС-КУРСЫ</h3>
          {coursePacketsData.map((courseData) => (
            <SwiperSlide key={courseData.id}>
              <CoursePacket {...courseData} />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </section>
  );
};
export default CoursePackets;
