import React, { FC } from 'react';
import Text from '../../ui/Text/Text';
import useDeviceTypeDetector from '../../hooks/useDeviceTypeDetector/useDeviceTypeDetector';

export interface TConditionItem {
  image: string;
  title1: string;
  title2: string;
  description: string;
}

const ConditionItem: FC<TConditionItem> = ({ title1, title2, description, image }) => {
  const { isDesktop, isMobile } = useDeviceTypeDetector();

  return (
    <div className='condition-item'>
      <div className='header-row'>
        <Text
          variant='subtitle1'
          className='title-holder'
          fontWeight={500}
          textTransform={isDesktop ? 'upperCase' : null}
        >
          {isDesktop ? (
            <>
              <span className='title'>{title1}</span>
              <span className='title'>{title2}</span>
            </>
          ) : (
            `${title1} ${title2}`
          )}
        </Text>
        {!isMobile && <img src={image} alt='' className='icon-image' />}
      </div>
      <Text variant='body1' fontWeight={400} className='description'>
        {description}
      </Text>
    </div>
  );
};

export default ConditionItem;
