import React, { ButtonHTMLAttributes, FC, ReactNode } from 'react';
import cn from 'classnames';

import './button.scss';

type TVariant = 'default' | 'primary' | 'secondary' | 'tertiary';

type TSize = 'small' | 'large';

interface TButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  fullWidth?: boolean;
  size?: TSize;
  variant?: TVariant;
  children?: ReactNode;
}

const Button: FC<TButton> = ({
  size,
  children = '',
  variant = 'default',
  className,
  fullWidth = false,
  ...props
}) => {
  const buttonClassName = cn('shared-btn', className, variant, size, fullWidth && 'full-width');

  return (
    <button className={buttonClassName} {...props}>
      {children}
    </button>
  );
};

export default Button;
