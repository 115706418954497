import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

import Text from '../../../ui/Text/Text';
import BusinessTarget from './BusinessTarget/BusinessTarget';
import useDeviceTypeDetector from '../../../hooks/useDeviceTypeDetector/useDeviceTypeDetector';

import { businessTargetsData } from './OwnBusiness.mocks';

import 'swiper/css/pagination';

import './ownBusiness.scss';

const OwnBusiness = () => {
  const { isTablet, isMobile } = useDeviceTypeDetector();

  const showSlider = isMobile || isTablet;

  return (
    <section className='container own-business-section'>
      <Text className='section-title' variant='h3' textTransform='upperCase' fontWeight={600}>
        Зачем создавать собственный бизнес?
      </Text>
      <Text className='section-subTitle' variant='subtitle4'>
        Бизнес создают те, кто ставит цели без границ и думает о своем будущем
      </Text>
      {showSlider ? (
        <div className='swiper-holder'>
          <Swiper pagination spaceBetween={8} slidesPerView={1} modules={[Pagination]}>
            {businessTargetsData.map((target, i) => (
              <SwiperSlide key={i}>
                <BusinessTarget {...target} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      ) : (
        <div className='business-targets-holder'>
          {businessTargetsData.map((target, i) => (
            <BusinessTarget key={`${i}`} {...target} />
          ))}
        </div>
      )}
    </section>
  );
};

export default OwnBusiness;
