import React from 'react';

import Text from '../../../ui/Text/Text';
import Button from '../../../ui/Button/Button';
import useDeviceTypeDetector from '../../../hooks/useDeviceTypeDetector/useDeviceTypeDetector';

import { BenefitsData } from './Benefits.constants';
import { enrollCourseId } from '../CourseDetails';
import { scrollElementIntoView } from '../../../utils/helpers';

import './benefits.scss';

const Benefits = () => {
  const { isMobile } = useDeviceTypeDetector();
  return (
    <section className='benefits'>
      <div className='container'>
        <div className='benefits-holder'>
          <Text variant='h3' className='title' fontWeight={600}>
            ЧТО ТЫ ПОЛУЧИШЬ?
          </Text>
          <div className='benefit-items'>
            <div className='benefit-items-row'>
              {BenefitsData.slice(0, BenefitsData.length / 2).map(({ id, text }) => (
                <div key={id} className='benefit'>
                  <Text variant={isMobile ? 'body1' : 'subtitle3'}>{text}</Text>
                </div>
              ))}
            </div>
            <div className='benefit-items-row'>
              {BenefitsData.slice(BenefitsData.length / 2).map(({ id, text }) => (
                <div key={id} className='benefit'>
                  <Text variant={isMobile ? 'body1' : 'subtitle3'}>{text}</Text>
                </div>
              ))}
            </div>
          </div>
          <div className='subscription-row'>
            <div className='price-holder'>
              <Text variant='h3' className='actual-price' fontWeight={500}>
                33 333 ₽
              </Text>
              <Text variant='subtitle2' className='old-price' fontWeight={300}>
                66 666 ₽
              </Text>
            </div>
            <Button
              variant='secondary'
              type='submit'
              className='submit-btn'
              onClick={() => scrollElementIntoView(enrollCourseId)}
            >
              <Text variant='body1' fontWeight={600}>
                Записаться
              </Text>
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Benefits;
