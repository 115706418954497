import subtract_1_large from '../../assets/media/freeConsultation/subtract-a-large.svg';
import subtract_2_large from '../../assets/media/freeConsultation/subtract-b-large.svg';
import subtract_3_large from '../../assets/media/freeConsultation/subtract-c-large.svg';

import subtract_1_medium from '../../assets/media/freeConsultation/subtract-a-medium.svg';
import subtract_2_medium from '../../assets/media/freeConsultation/subtract-b-medium.svg';
import subtract_3_medium from '../../assets/media/freeConsultation/subtract-c-medium.svg';

import subtract_1_small from '../../assets/media/freeConsultation/subtract-a-small.svg';
import subtract_2_small from '../../assets/media/freeConsultation/subtract-b-small.svg';
import subtract_3_small from '../../assets/media/freeConsultation/subtract-c-small.svg';

export const ConsultationSteps = [
    {
        icon: [subtract_1_small, subtract_1_medium, subtract_1_large],
        text: 'Сформируй цели'
    },
    {
        icon: [subtract_2_small, subtract_2_medium, subtract_2_large],
        text: 'Определи точки своего роста'
    },
    {
        icon: [subtract_3_small, subtract_3_medium, subtract_3_large],
        text: 'Составь план достижения целей'
    }
]