import React from 'react';
import cn from 'classnames';

import { IIconProps } from './Icon.types';
import { IconSizeMap } from './Icon.constants';

import './icon.scss';

const Icon: React.FC<IIconProps> = ({ className = '', name, size = 24 }) => {
  const iconClassName = cn('icon', name, className, [IconSizeMap[size]]);

  return <i className={iconClassName} />;
};

export default Icon;
