import React from 'react';

import Text from '../../../ui/Text/Text';

import useDeviceTypeDetector from '../../../hooks/useDeviceTypeDetector/useDeviceTypeDetector';

import { OurAuditoriumItems } from './OurAuditorium.constants';

import auditoriumPng from '../../../assets/media/ourAuditorium/auditorium.png';
import auditoriumPngSmall from '../../../assets/media/ourAuditorium/auditorium-small.png';

import './ourAuditorium.scss';

const OurAuditorium = () => {
  const { isTablet, isMobile } = useDeviceTypeDetector();
  return (
    <section className='our-auditorium'>
      <div className='container'>
        <div className='our-auditorium-holder'>
          <Text variant='h2' className='section-header' fontWeight={600}>
            ДЛЯ КОГО НАШИ БИЗНЕС-КУРСЫ?
          </Text>
          <div className='our-auditorium-items'>
            {OurAuditoriumItems.map(({ id, text }) => (
              <div className='our-auditorium-item' key={id}>
                <Text variant='secondarySubtitle2' className='item-text'>
                  {text}
                </Text>
              </div>
            ))}
          </div>
          <div className='our-auditorium-image'>
            <img
              src={isTablet || isMobile ? auditoriumPngSmall : auditoriumPng}
              alt='Our auditorium'
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurAuditorium;
