export const OurAuditoriumItems = [
    {
        id: 0,
        text: 'Для тех, кто хочет открыть бизнес, но не знает, с чего начать'
    },
    {
        id: 1,
        text: 'Для тех, кто открывал бизнес, но не достиг целей '
    },
    {
        id: 2,
        text: 'Для тех, кто открыл бизнес и хочет масштаба'
    }
];