import {AllowedTagNames, FontWeightType, TextVariantType} from './Text.types';

export const TextVariantTagMap: Record<TextVariantType, AllowedTagNames> = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    subtitle4: 'h3',
    subtitle1: 'h4',
    subtitle2: 'h5',
    subtitle3: 'h6',
    secondarySubtitle1: 'h4',
    secondarySubtitle2: 'h5',
    body1: 'p',
    body2: 'p',
    body3: 'p',
    label1: 'span',
    label2: 'span',
};

export const TextWeightClassNameMap: Record<FontWeightType, string> = {
    300: 'light',
    400: 'regular',
    500: 'medium',
    600: 'semiBold',
    700: 'bold',
    900: 'black',
}