import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import cn from 'classnames';

import Text from '../../ui/Text/Text';
import Icon from '../../ui/Icon/Icon';
import DrawerMenu from './DrawerMenu/DrawerMenu';
import useDeviceTypeDetector from '../../hooks/useDeviceTypeDetector/useDeviceTypeDetector';

import { AppRoutes } from '../../utils/constants';

import logoImg from '../../assets/media/logo.svg';
import logoImgSmall from '../../assets/media/logo-small.svg';

import './header.scss';

interface IHeaderProps {
  isDrawerOpen: boolean;
  onDrawerOpenChange: (open: boolean) => void;
}

function Header({ isDrawerOpen, onDrawerOpenChange }: IHeaderProps) {
  const { isDesktop, isLaptop } = useDeviceTypeDetector();
  const navLinkClassName = ({ isActive }: { isActive: boolean }) =>
    `nav-link ${isActive ? 'nav-link-active' : ''}`;

  const isDesktopSize = isDesktop || isLaptop;

  return (
    <header className={cn('header', isDrawerOpen && 'header-drawer-open')}>
      <div className='container'>
        <div className='header-holder'>
          {!isDesktopSize && (
            <button
              className='header-hamburger-button'
              onClick={() => onDrawerOpenChange(!isDrawerOpen)}
              aria-label='Hamburger menu'
            >
              <Icon size={24} name='hamburger' />
            </button>
          )}
          <Link to={AppRoutes.home} className='logo-section'>
            <img src={isDesktopSize ? logoImg : logoImgSmall} alt='Site logo' className='logo' />
          </Link>
          {isDesktopSize && (
            <nav className='nav-menu'>
              <NavLink to='/' className={navLinkClassName}>
                <Text variant='label1' fontWeight={500}>
                  Главная
                </Text>
              </NavLink>
              <NavLink to='/entrepreneur-school' className={navLinkClassName}>
                <Text variant='label1' fontWeight={500}>
                  Школа предпринимателя
                </Text>
              </NavLink>
              <NavLink to='/courses' className={navLinkClassName}>
                <Text variant='label1' fontWeight={500}>
                  Курсы
                </Text>
              </NavLink>
              <NavLink to='/free-consultation' className={navLinkClassName}>
                <Text variant='label1' fontWeight={500}>
                  Бесплатная консультация
                </Text>
              </NavLink>
            </nav>
          )}
          <DrawerMenu isDrawerOpen={isDrawerOpen} onClose={() => onDrawerOpenChange(false)} />
        </div>
      </div>
    </header>
  );
}

export default Header;
