export const BenefitsData = [
    {
        id: 0,
        text: 'Выберешь и проверишь свою нишу'
    },
    {
        id: 1,
        text: 'Проанализируешь рынок'
    },
    {
        id: 2,
        text: 'Разработаешь MPV'
    },
    {
        id: 3,
        text: 'Подберешь команду'
    },
    {
        id: 4,
        text: 'Создашь точки контакта'
    },
    {
        id: 5,
        text: 'Настроишь клиентский поток'
    },
    {
        id: 6,
        text: 'Разработаешь полноценный продукт'
    },
    {
        id: 7,
        text: 'Построишь систему продаж'
    },
    {
        id: 8,
        text: 'Сформируешь бизнес-систему'
    },
    {
        id: 9,
        text: 'Начнешь масштабироваться'
    }
]